/* eslint-disable prettier/prettier */
const {
  // VUE_APP_APIKEY,
  VUE_APP_BASE_URL,
  VUE_APP_SITE_URL,
  NODE_ENV = "",
  // VUE_APP_STRIPE_PUBLIC_KEY,
  VUE_APP_URL_MEDIA,
} = process.env;

const environment = NODE_ENV.toLowerCase();
const apiBaseUrl = VUE_APP_BASE_URL;
const siteUrl = VUE_APP_SITE_URL;
// const apikey = VUE_APP_APIKEY;
// const stripePublicKey = VUE_APP_STRIPE_PUBLIC_KEY;
const urlMedia = VUE_APP_URL_MEDIA;
export { environment, apiBaseUrl, siteUrl, /*apikey, stripePublicKey*/ urlMedia };













































































































































































































































































































































import Vue from "vue";
import { mixTruck } from "@/mixins/truck";
import Axios from "axios";
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";
export default Vue.extend({
  name: "truck",
  mixins: [mixTruck],
  components: {
    VueHcaptcha,
  },
  props: {
    sold: Boolean,
  },
  data() {
    return {
      expanded: undefined,
      dialog: false,
      activePicker: "",
      menu: false,
      error: false,
      errorMessage: "",
      name: "",
      lastname: "",
      current_address: "",
      street_address: "",
      city: "",
      state: "",
      zip_code: "",
      license: "",
      social_security: "",
      birth: "",
      phone: "",
      verified: false,
      expired: false,
      captchaKey: "",
      vehiculo: "",
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  computed: {
    imageHeight(): number {
      let result = 0;
      const { sm, md } = this.$vuetify.breakpoint;

      if (sm) result = 720 * 0.68;
      else if (md) result = 300;
      else result = 250;

      return result;
    },
  },
  methods: {
    onVerify(token: string, captchaKey: string) {
      this.verified = true;
      this.captchaKey = captchaKey;
    },
    onExpire() {
      this.verified = false;
      this.expired = true;
    },
    save(date: any) {
      this.birth = date;
    },
    collapseDetails() {
      this.expanded = undefined;
    },
    applyNow(vin: any) {
      this.dialog = true;
      this.vehiculo = vin;
    },
    send() {
      if (
        this.name !== "" &&
        this.lastname !== "" &&
        this.current_address !== "" &&
        this.social_security !== "" &&
        this.phone !== ""
      ) {
        if (this.captchaKey !== "") {
          this.dialog = false;
          Axios.post("https://api.jatitrucksales.com/api/rest/v1/bookings", {
            name: this.name,
            lastname: this.lastname,
            current_address: this.current_address,
            street_address: this.street_address,
            city: this.city,
            state: this.state,
            zip_code: this.zip_code,
            license: this.license,
            social_security: this.social_security,
            birth: this.birth,
            phone: this.phone,
            hcaptcha: this.captchaKey,
            vin: this.vehiculo,
          }).then((response) => {
            if (response.status == 201) {
              this.error = false;
              this.errorMessage = "";
              this.name = "";
              this.lastname = "";
              this.current_address = "";
              this.street_address = "";
              this.city = "";
              this.state = "";
              this.zip_code = "";
              this.license = "";
              this.social_security = "";
              this.birth = "";
              this.phone = "";
              this.verified = false;
              this.expired = false;
              this.captchaKey = "";
              this.vehiculo = "";
            }

            this.dialog = false;
          });
        } else {
          this.errorMessage =
            "You are human? You forgot to confirm the captcha...";
          this.error = true;
          return;
        }
      } else {
        this.errorMessage =
          "You must complete all required fields before submitting the application";
        this.error = true;
      }
    },
  },
});

import Vue from "vue";

export const svgIcon = Vue.extend({
  props: {
    size: {
      type: [String, Number],
      default(): number {
        return 24;
      },
    },
  },
});

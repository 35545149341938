import Vue from "vue";
import { mapMutations } from "vuex";
import { MUT_TRUCKS, MUT_UPDATE_TRUCKS } from "@/store/mutations/types";
import { httpClient } from "@/services";

export const pagination = Vue.extend({
  data() {
    return {
      currentPage: 1,
      total: 0,
      count: 0,
      loading: false,
    };
  },
  computed: {
    pages(): number {
      return (
        Math.floor(this.total / this.perPage) +
        (this.total % this.perPage > 0 ? 1 : 0)
      );
    },
    skip(): number {
      return (this.currentPage - 1) * this.perPage;
    },
    paginationParams(): string {
      const params = `limit=${this.perPage}&skip=${this.skip}`;

      return params;
    },
    perPage(): number {
      const { lg, xl } = this.$vuetify.breakpoint;
      let result = 8;

      if (lg) result = 9;
      else if (xl) result = 12;

      return result;
    },
  },
  methods: {
    ...mapMutations([MUT_TRUCKS, MUT_UPDATE_TRUCKS]),
    async getTrucks($infiniteState: any, url: string): Promise<any> {
      this.loading = true;
      const responseData = (await httpClient.get(url)).data;

      this.total = responseData.total;
      this.count = responseData.count;

      if (responseData.count == 0) {
        $infiniteState.complete();
        this.loading = false;
        // console.log("complete");

        return;
      }

      /**
       * Si lo que se recibe es menor que la cantidad
       * de elementos por página, se completa la peticion
       * y se actualiza con lo que llega.
       */
      this.currentPage = this.currentPage + 1;

      if (responseData.count < this.perPage) {
        this[MUT_UPDATE_TRUCKS](responseData.data);
        $infiniteState.loaded();
        this.loading = false;
        // console.log("loaded");

        return;
      }

      this[MUT_UPDATE_TRUCKS](responseData.data);
    },
    resetParams(): void {
      this.currentPage = 1;
      this.total = 0;
      this.count = 0;
      this.loading = false;
    },
  },
  mounted() {
    this[MUT_TRUCKS]([]);
  },
});

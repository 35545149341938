/**
 * @description Base truck
 */

import { urlMedia } from "@/enviorment";
import Vue from "vue";

/**
 * Example data
 * status: SOLD, IN_STORE
 * 
  {
      "_id": "60b8d949ebd5b800156dbe4d",
      "active": true,
      "pictureUrls": [
          "1622727016054pe36481twwjxdc74d2u28j.png",
          "1622727016082upo3w3ijz99vowm315s25.png",
          "1622727016097t1b17v86tfj8e3oj55ey7a.png"
      ],
      "mileage": "1200000 mi",
      "transmission": "FWD",
      "exterior_color": "White",
      "interior_color": "Blue",
      "status": "IN_STORE",
      "vin": "123456dg",
      "wheelBase": "220",
      "code": "d5fc47",
      "year": "2021",
      "model": [
          {
              "_id": "60ae9611223d9100111fc8e7",
              "active": true,
              "brandId": "60ae95f1223d9100111fc8e3",
              "name": "Model 4",
              "brand": {
                  "_id": "60ae95f1223d9100111fc8e3",
                  "active": true,
                  "name": "Brand 2",
              }
          }
      ],
      "engineModel": [
          {
              "_id": "60b8d910ebd5b800156dbe4b",
              "active": true,
              "manufacturerId": "60b8d906ebd5b800156dbe4a",
              "name": "Model 1",
              "createdAt": "2021-06-03T13:28:48.000Z",
              "updatedAt": "2021-06-03T13:28:48.000Z",
              "manufacturer": {
                  "_id": "60b8d906ebd5b800156dbe4a",
                  "active": true,
                  "name": "Engine 1"
              }
          }
      ]
  },
 */

export const mixTruck = Vue.extend({
  props: {
    _id: String,
    active: Boolean,
    mileage: String,
    condition: String,
    transmission: String,
    drivetrain: String,
    exterior_color: String,
    interior_color: String,
    status: String,
    createdAt: String,
    pictureUrls: Array,
    year: String,
    vin: String,
    stock: String,
    price: String,
    model: {
      type: Array,
      // Example data
      default: () => [
        {
          _id: "60ae9611223d9100111fc8e7",
          active: true,
          brandId: "60ae95f1223d9100111fc8e3",
          name: "Model 4",
          createdAt: "2021-05-26T18:40:17.000Z",
          updatedAt: "2021-05-26T18:40:17.000Z",
          __v: 0,
          brand: {
            _id: "60ae95f1223d9100111fc8e3",
            active: true,
            name: "Brand 2",
            createdAt: "2021-05-26T18:39:45.000Z",
            updatedAt: "2021-05-26T18:39:45.000Z",
            __v: 0,
          },
        },
      ],
    },
    engineModel: {
      type: Array,
      default: () => [
        {
          _id: "60b8d910ebd5b800156dbe4b",
          active: true,
          manufacturerId: "60b8d906ebd5b800156dbe4a",
          name: "Model 1",
          createdAt: "2021-06-03T13:28:48.000Z",
          updatedAt: "2021-06-03T13:28:48.000Z",
          manufacturer: {
            _id: "60b8d906ebd5b800156dbe4a",
            active: true,
            name: "Engine 1",
          },
        },
      ],
    },
  },
  computed: {
    description(): string {
      const model = this.model[0];
      const brand = model != undefined ? (model as any).brand : "";

      if (model == null || model == undefined) {
        return "";
      }

      return `${brand != undefined ? (brand as any).name : ""}`;
    },
    mileageRed(): string {
      return new Intl.NumberFormat().format(parseInt(this.mileage));
    },
    imagePath(): string | unknown {
      const { pictureUrls } = this;
      return `${urlMedia}camion/${this._id}/${pictureUrls[0]}`;
    },
  },
});

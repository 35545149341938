



















import Vue from "vue";
import Truck from "./Truck.vue";

export default Vue.extend({
  components: { Truck },
  name: "truck-list",
  props: {
    items: Array,
  },
});
